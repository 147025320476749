import React from "react";

const Statistics = (props) => {
  return (
    <div id="statistics">
      <div className="container">
        <div className="left-column">
          <h2 style={{ fontStyle: "italic" }}>
            {props.title}"FROM YOUR EYES' AI Model"
          </h2>
          <p style={{ marginTop: "40px" }}>
            {props.paragraph}It makes Real-Time Image Processing and video image processing accessible to end users. It is trained using a unique dataset containing over 15 million visual data and over 700 Classes. Makes everything easier for smart technologies to access artificial vision solutions.
          </p>
        </div>
        <div className="right-column">
          <ul>
            <li>
              <img
                style={{ width: "100px" }}
                src={"img/statistics/bounding-box.png"}
                alt="Icon 1"
              />
              <hr />
              <span>{props.regionCount}700+</span>
              <p>Classes</p>
            </li>
            <li>
              <img
                style={{ width: "100px" }}
                src={"img/statistics/target.png"}
                alt="Icon 2"
              />
              <hr />
              <span>{props.cityCount}Closest</span>
              <p>Human Vision</p>
            </li>
            <li>
              <img
                style={{ width: "100px" }}
                src={"img/statistics/image-processing.png"}
                alt="Icon 3"
              />
              <hr />
              <span>{props.countryCount}Real Time</span>
              <p>Image Processing</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
