import React from "react";

const ThreeRectangles = () => {
  return (
    <div id="threeRectangles">
      <div className="container" style={{ marginTop: "-120px" }}>
        <div className="row justify-content-around">
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "80px",
                  marginTop: "0px", // Adjusted to move image 10px down
                  marginBottom: "0px",
                }}
                src={"img/statistics/artificial-intelligence.png"}
                alt="Icon 2"
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  marginTop: "10px", // Added to move text 10px down
                }}
                className="text"
              >
                Large Vision Model
              </div>
              <div className="text" style={{ marginTop: "10px" }}> {/* Added marginTop */}
                FROM YOUR EYES has decided to develop its own AI model to
                address this issue. This model is trained using a unique dataset
                containing over 15 million visual data and over 700 classes in
                it.
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "80px",
                  marginTop: "0px", // Adjusted to move image 10px down
                  marginBottom: "0px",
                }}
                src={"img/statistics/ai (1).png"}
                alt="Icon 1"
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  marginTop: "10px", // Added to move text 10px down
                }}
                className="text"
              >
                Human Perception
              </div>
              <div className="text" style={{ marginTop: "10px" }}> {/* Added marginTop */}
                During development of its model, FROM YOUR EYES asked the
                question, "What does a human biologically need to see?" This
                ensured that the model works closer to human perception and
                needs.
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "80px",
                  marginTop: "0px", // Adjusted to move image 10px down
                  marginBottom: "0px",
                }}
                src={"img/statistics/ai (2).png"}
                alt="Icon 3"
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  marginTop: "10px", // Added to move text 10px down
                }}
                className="text"
              >
                Smart Technology
              </div>
              <div className="text" style={{ marginTop: "10px" }}> {/* Added marginTop */}
                This approach helps humans gain access to technological vision
                while also making it easier for smart technologies to access
                artificial vision solutions.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeRectangles;
