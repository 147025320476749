import React, { useState } from 'react';

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        occupation: '',
        reason: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch('/api/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        if (response.ok) {
            alert('Message sent successfully!');
            // Optionally reset the form here
            setFormData({
                name: '',
                email: '',
                occupation: '',
                reason: ''
            });
        } else {
            alert('Failed to send message. Please try again.');
        }
    };

    // Inline styles
    const formContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        marginTop: '20px'
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%' // Adjust width as needed
    };

    const inputStyle = {
        marginBottom: '10px',
        width: '90%', // Ensures all inputs are the same width
        padding: '10px'
    };

    const buttonStyle = {
        width: '90%',
        padding: '10px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
    };

    return (
        <div style={formContainerStyle}>
            <h2>Pre-Enroll for API Access</h2>
            <form style={formStyle} onSubmit={handleSubmit}>
                <label>
                    Your Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} style={inputStyle} />
                </label>
                <label>
                    Your Email:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} style={inputStyle} />
                </label>
                <label>
                    Your Occupation:
                    <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} style={inputStyle} />
                </label>
                <label>
                    How will you use the API:
                    <textarea name="reason" value={formData.reason} onChange={handleChange} style={{...inputStyle, height: '100px'}} />
                </label>
                <button type="submit" style={buttonStyle}>Send</button>
            </form>
        </div>
    );
}

export default ContactForm;
