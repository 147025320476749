import React from "react";

export const FocusPointsPath = (props) => {
  // Son resmi alalım
  const lastItem = props.data[props.data.length - 1];

  return (
    <>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
              HAKKIMIZDA
            </h2>
          </div>
        </div>
      </div>
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>What We Propose</h2>
          </div>
          <div className="row justify-content-center">
            {props.data
              ? props.data.slice(0, -1).map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <div className="icon-container">
                      <img src={d.icon} alt={d.name} className="resim" />
                    </div>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                    </div>
                  </div>
                ))
              : "loading"}
            {/* Son resmi ekleyelim ve ekstra bir class ekleyelim */}
            <div className="col-md-4 last-item">
              <div className="icon-container">
                <img
                  src={lastItem.icon}
                  alt={lastItem.name}
                  className="resim"
                />
              </div>
              <div className="service-desc">
                <h3>{lastItem.name}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
