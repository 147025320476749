import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleInstagramClick = () => {
    window.location.href = "https://www.instagram.com/fromyoureyes_world?igsh=eHhwa3cwdmJ5N3Fv"; // Buraya Instagram adresinizi yazın
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@fromyoureyes.app"; // Buraya e-posta adresinizi yazın
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="col-md-6 contact-info">
            <div
              className="contact-item"
              onClick={handleInstagramClick}
              style={{ cursor: "pointer" }}
            >
              {/* <h3>İletişim Bilgileri</h3> */}
              <p>
                <span>
                  <i className="fa fa-instagram"></i> Instagram
                </span>
                <href>{props.data ? props.data.address : "loading"}</href>
              </p>
            </div>
            <div
              className="contact-item"
              onClick={handleEmailClick}
              style={{ cursor: "pointer" }}
            >
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
