import React from "react";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  // Hakkımızda menüsünün durumunu takip eden bir state kullanacağız
  const [isAboutHovered, setIsAboutHovered] = React.useState(false);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img
              src={"img/navigation/logo_fye.png"}
              alt="Logo"
              style={{
                maxWidth: "280px",
                maxHeight: "180px",
                marginTop: "-15px",
                marginLeft: "-40px",
              }}
            />{" "}
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li
              onMouseEnter={() => setIsAboutHovered(true)}
              onMouseLeave={() => setIsAboutHovered(false)}
            >
              <Link to="/rotaract" className="page-scroll">
                FYE
              </Link>
              {isAboutHovered && (
                <ul className="submenu">
                  <li>
                    <Link to="/rotaract">FYE Mobile</Link>
                  </li>
                  <li>
                    <Link to="/rotary">FYE Vehicle Vision</Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
